import { request } from "./api";
import { API_BASE_URL, SERVERCOGS } from "../../constants";
import { getApi } from "../../utility/functions/publicFunctions";

export function create(id, body, form) {
	let tipe = getApi(form, "create");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getItemBalanceWarehouses(id, body, form) {
	let tipe = getApi(form, "itembalancewarehouses");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getAll(id, form) {
	let tipe = getApi(form, "readall");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "GET",
	});
}
export function getSelectedInvoiceConsolidates(id, form, ids) {
	let tipe = getApi(form, "getAllInvoiceConsolidate");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${ids}`,
		method: "GET",
	});
}
export function getAllConsolidate(id, form, params = null) {
	let tipe = getApi(form, "pullConsolidates");
	let additionalParams = "";
	if (params) {
		const { customerid } = params;
		additionalParams = `customerid=${customerid}`;
	}
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}?${additionalParams}`,
		method: "GET",
	});
}
export function getAllReccuringByType(id, params) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/recurring?${params}`,
		method: "GET",
	});
}
export function getAllSalesmanBySuspend(id, form, params) {
	let tipe = getApi(form, "readall");
	const { suspended } = params;
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}?suspended=${suspended}`,
		method: "GET",
	});
}

export function getAllSubItem(id, form, params) {
	let tipe = getApi(form, "readallsubitem");
	const { itemid, itemtype } = params;
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}?itemid=${itemid}&itemtype=${itemtype}`,
		method: "GET",
	});
}

export function getFilteredItem(id, params) {
	const { itemno = "", itemdescription = "" } = params;
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/filter-item?search=itemno:${itemno},itemdescription:${itemdescription}`,
		method: "GET",
	});
}

export function getAllBase(id, form) {
	let tipe = getApi(form, "readallBase");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "GET",
	});
}

export function getAll2({ id, initForm, params }) {
	let tipe = getApi(initForm, "readall");
	let path = `${tipe}`;
	if (params) {
		let { size, page, search } = params;
		path = `${tipe}?page=${page}&search=${search}&size=${size}`;
	}

	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${path}`,
		method: "GET",
	});
}

export function getUserBranch(id, branchid) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/user-branch/${branchid}`,
		method: "GET",
	});
}

export function getPurchaseItem(id, form) {
	let tipe = getApi(form, "readallPembelian");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "GET",
	});
}

export function getType(id, form) {
	let tipe = getApi(form, "type");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "GET",
	});
}

export function getNumber(id, form) {
	let tipe = getApi(form, "getnumber");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "GET",
	});
}

export function getArrayNumber(id, multiply, sourceno) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/generate-number/?multiply=${multiply}&sourceno=${sourceno}`,
		method: "GET",
	});
}

export function getNumberBukuBesar(id, form, formType) {
	let tipe = getApi(form, "getnumber");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${formType}`,
		method: "GET",
	});
}

export function getBank(id, vendorId, form) {
	let tipe = getApi(form, "getBank");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${vendorId}`,
		method: "GET",
	});
}

export function getItemUnit(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "getItemUnit");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getNumberByTipe(id, form, jenis) {
	let tipe = getApi(form, "getnumber");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${jenis}`,
		method: "GET",
	});
}

export function generateNumber(tenantid, params) {
	const { number, type, id } = params;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantid}/master/generate-number-trans/?id=${id}&number=${number}&type=${type}`,
		method: "GET",
	});
}

export function updatePrintedStamp(tenantid, body) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantid}/master/update-printed`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

// export function getAllByTipe(id, form, jenis, { ...params }) {
export function getAllByTipe({ tenantId, form, jenis, params }) {
	let tipe = getApi(form, "readallByTipe");
	let path = `${jenis}`;
	if (params) {
		const { size, page, idtype, search } = params;
		path = `${jenis}?size=${size}&page=${page}&idtype=${idtype}&search=${search}`;
	}
	return request({
		url: API_BASE_URL + `/tenant/${tenantId}/master/${tipe}/${path}`,
		method: "GET",
	});
}

export function getAccntByCurrency(params) {
	const { id, form, jenis, personId, paramType, type, currID } = params;
	let tipe = getApi(form, "accntCurrency");
	let __EndPoint;
	if (paramType === 1) {
		__EndPoint = `${API_BASE_URL}/tenant/${id}/master/${tipe}/${jenis}/${personId}`;
	} else if (paramType === 2) {
		__EndPoint = `${API_BASE_URL}/tenant/${id}/master/${tipe}/true/${type}/${currID}`;
	}
	return request({
		url: __EndPoint,
		method: "GET",
	});
}

export function get(body, withDP = false) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "read");
	let withDPParams = "";
	if (withDP) {
		withDPParams = `/?withDP=${withDP}`;
	}
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}${withDPParams}`,
		method: "GET",
	});
}

export function getPrint(body, withDP = false) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "print");
	let withDPParams = "";
	if (withDP) {
		withDPParams = `/?withDP=${withDP}`;
	}
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}${withDPParams}`,
		method: "GET",
	});
}

export function getTemplate(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "readByType");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getTemplateID(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "templatedefault");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getScreen(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "getScreen");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getNo(body) {
	const { tenantId, no, form } = body;
	let tipe = getApi(form, "readNo");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${no}`,
		method: "GET",
	});
}

export function getDiprosesOleh(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "diproses");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getDiprosesRetur(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "diprosesRetur");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getlastrecord(body) {
	const { tenantId, form } = body;
	let tipe = getApi(form, "lastrecord");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}`,
		method: "GET",
	});
}

export function getlastrecordGroupping(body) {
	const { tenantId, form } = body;
	let tipe = getApi(form, "lastrecord");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}`,
		method: "GET",
	});
}

export function getlastrecordByTipe(body) {
	const { tenantId, form, jenis } = body;
	let tipe = getApi(form, "lastrecord");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${jenis}`,
		method: "GET",
	});
}

export function deleted(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "delete");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "DELETE",
	});
}

export function update(id, body, form) {
	let tipe = getApi(form, "update");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function createMasterDetail(
	id,
	body,
	form,
	openJurnal = false,
	{ ...props }
) {
	const { cnt, email, attachment } = props;
	let tipe = getApi(form, "create");
	let path = `${tipe}`;
	if (openJurnal) {
		path = `${tipe}/1`;
	} else if (cnt) {
		path = `${tipe}?cnt=${cnt}`;
	} else if (email) {
		path = `${tipe}?email=${email}`;
	}
	// let formData = new FormData();
	// formData.append("pAllPurchaseOrder", body);
	// formData.append("attachment", attachment);
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${path}`,
		method: "POST",
		body: JSON.stringify(body),
		// body: formData,
	});
}

export function saveId(id, body) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/save-invoice-id`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function deletedInvoice(id) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/delete-invoice-id`,
		method: "DELETE",
	});
}

export function updateMasterDetail(
	id,
	body,
	form,
	openJurnal = false,
	{ ...props }
) {
	const { cnt, email } = props;
	let tipe = getApi(form, "update");
	let path = `${tipe}`;
	if (openJurnal) {
		path = `${tipe}/1`;
	} else if (cnt) {
		path = `${tipe}?cnt=${cnt}`;
	} else if (email) {
		path = `${tipe}?email=${email}`;
	}
	// let formData = new FormData();
	// formData.append("pAllPurchaseOrder", JSON.stringify(body));
	// formData.append("attachment", attachment);
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${path}`,
		method: "PUT",
		body: JSON.stringify(body),
		// body: formData,
	});
}

export function paymentPurchase(id, body, form, openJurnal = false) {
	let tipe = getApi(form, "create");
	return request({
		url:
			openJurnal === false
				? `${API_BASE_URL}/tenant/${id}/master/${tipe}`
				: `${API_BASE_URL}/tenant/${id}/master/${tipe}/1`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function updatePaymentPurchase(id, body, form, openJurnal = false) {
	let tipe = getApi(form, "update");
	return request({
		url:
			openJurnal === false
				? `${API_BASE_URL}/tenant/${id}/master/${tipe}`
				: `${API_BASE_URL}/tenant/${id}/master/${tipe}/1`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function getInvoiceCheque(id, param, form) {
	const { vendorId, chequeId } = param;
	let tipe = getApi(form, "getListInvoiceCheque");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${chequeId}/${vendorId}`,
		method: "GET",
	});
}

export function createMasterDetailSubDetail(
	id,
	body,
	form,
	openJurnal = false,
	{ ...props }
) {
	const { email } = props;
	let tipe = getApi(form, "create");
	let path = `${tipe}`;
	if (openJurnal) {
		path = `${tipe}/1`;
	} else if (email) {
		path = `${tipe}?email=${email}`;
	}
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${path}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function updateMasterDetailSubDetail(
	id,
	body,
	form,
	openJurnal = false,
	{ ...props }
) {
	const { email } = props;
	let tipe = getApi(form, "update");
	let path = `${tipe}`;
	if (openJurnal) {
		path = `${tipe}/1`;
	} else if (email) {
		path = `${tipe}?email=${email}`;
	}
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${path}`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function pullTransaction(body) {
	const { tenantId, form, vendor, jenis, jenisDP, tipeTarikan } = body;
	let tipe = getApi(form, "pullTransaction");
	if (jenis !== undefined) {
		tipe = getApi(form, jenis);
	}
	if (vendor === undefined) {
		return request({
			url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}`,
			method: "GET",
		});
	}
	if (jenisDP !== undefined) {
		return request({
			url:
				API_BASE_URL +
				`/tenant/${tenantId}/master/${tipe}/${jenisDP}/${vendor}`,
			method: "GET",
		});
	}
	if (tipeTarikan !== undefined) {
		return request({
			url: API_BASE_URL + `/tenant/${tenantId}/master/${tipe}/${vendor}`,
			method: "GET",
		});
	}
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${vendor}`,
		method: "GET",
	});
}

export function pullDetTransaction(body) {
	const { tenantId, form, id, jenis } = body;
	let tipe = getApi(form, "pullDetTransaction");
	if (jenis !== undefined) {
		tipe = getApi(form, jenis);
	}
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getMemorized(id, type, form) {
	let tipe = getApi(form, "getmemorized");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${type}`,
		method: "GET",
	});
}

export function getInvRetur(body) {
	const { tenantId, vendorId, form } = body;
	let tipe = getApi(form, "getInvoiceRetur");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${vendorId}`,
		method: "GET",
	});
}
export function getItmRetur(body) {
	const { tenantId, invoiceId, form, returnId } = body;
	let tipe = getApi(form, "getItmRetur");
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/${tipe}/${returnId}/${invoiceId}`,
		method: "GET",
	});
}

export function getAkunByTipe(body) {
	const { personId, tenantId, form, jenis, suspend, parent, type, typeParam } =
		body;
	let tipe = getApi(form, "akunByTipe");
	let __EndPoint;
	if (personId !== undefined) {
		__EndPoint = `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${jenis}/${personId}`;
	} else if (typeParam === 2) {
		__EndPoint = `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${type}`;
	} else if (type === 3) {
		__EndPoint = `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${jenis}/true`;
	} else {
		__EndPoint =
			API_BASE_URL +
			`/tenant/${tenantId}/master/${tipe}/${suspend}/${parent}/${jenis}`;
	}
	return request({
		url: __EndPoint,
		method: "GET",
	});
}

export function getAkunBank(id, form, jenis) {
	let tipe = getApi(form, "akunByTipe");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${jenis}`,
		method: "GET",
	});
}

export function getAkunByNotTipe(body) {
	const { tenantId, form, tipeAkun } = body;
	let tipe = getApi(form, "shipCost");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${tipeAkun}`,
		method: "GET",
	});
}

export function getAkunJobCost(params) {
	const { tenantId } = params;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/glaccount-job-cost`,
		method: "GET",
	});
}

export function getNoFaktur(form, tenantId, personId, glId) {
	let tipe = getApi(form, "chargeToVendor");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${personId}/${glId}`,
		method: "GET",
	});
}

export function getLaporan(id, form, key, idnya = null) {
	let tipe = getApi(form, key);
	return request({
		url:
			idnya === null
				? `${API_BASE_URL}/tenant/${id}/master/${tipe}`
				: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${idnya}`,
		method: "GET",
	});
}

export function deleteLaporan(id, reportid) {
	return request({
		url: API_BASE_URL + `/tenant/${id}/master/report-format/${reportid}`,
		method: "DELETE",
	});
}

export function viewLaporan(id, body, form, key) {
	let tipe = getApi(form, key);
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getDefault(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "default");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function updateLayout(id, body, form) {
	let tipe = getApi(form, "updateLayout");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function getQtyItem(body) {
	const { warehouseId, form, id, itemno } = body;
	let tipe = getApi(form, "getQty");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/?itemid=${itemno}&warehouseid=${warehouseId}`,
		method: "GET",
	});
}
export function getQtySaldo(body) {
	const { itemno, date, form, id } = body;
	let tipe = getApi(form, "getQtySaldo");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}?itemno=${encodeURIComponent(
			itemno
		)}&ldate=${date}`,
		method: "GET",
	});
}

export function getTemplateField(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "templatefield");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getReconcileAccnt(body) {
	const { date, tenantId, idBank } = body;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/reconciliation-detail?glaccount=${idBank}&enddate=${date}`,
		method: "GET",
	});
}

export function getSaldoBank(param) {
	const { idBank, dateCheck, tenantId } = param;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/glaccount-opening-balance?glaccount=${idBank}&paymentdate=${dateCheck}`,
		method: "GET",
	});
}

export function getHakAkses(param) {
	const { tenantId, userId } = param;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/privileges/${userId}`,
		method: "GET",
	});
}

export function updateHakAkses(param) {
	const { tenantId, body } = param;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/privileges`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}
export function cloneHakAkses(param) {
	const { tenantId, userCopy, userSource } = param;
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/clone-privileges/${userCopy}/${userSource}`,
		method: "GET",
	});
}

export function apiImport(id, body, form) {
	let tipe = getApi(form, "import");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function DirectJournal(params) {
	const { tenantId, id, aktiva } = params;
	return request({
		url:
			aktiva === undefined
				? `${API_BASE_URL}/tenant/${tenantId}/master/general-ledger-history/${id}`
				: `${API_BASE_URL}/tenant/${tenantId}/master/general-ledger-history-fasset/${id}`,
		method: "GET",
	});
}

export function itemByWarehouse(params) {
	const { tenantId, date, itemno } = params;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/item-warehs?fdate=${date}&itemid=${itemno}`,
		method: "GET",
	});
}

export function RiwayatPersesdiaan({ tenantId, fromdate, itemno, todate }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/item-itemhist?itemno=${itemno}&fromdate=${fromdate}&todate=${todate}`,
		method: "GET",
	});
}
export function listFormatTanggal({ tenantId }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/preference-format-date`,
		method: "GET",
	});
}
export function getRiwayatAkun({
	tenantId,
	DFROM,
	DTO,
	VPERSONID,
	VGLACCOUNT,
	BAHASA,
	USERID,
}) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/glaccount-glhist?DFROM=${DFROM}&DTO=${DTO}&VPERSONID=${VPERSONID}&VGLACCOUNT=${VGLACCOUNT}&BAHASA=${BAHASA}&VUSERID=${USERID}`,
		method: "GET",
	});
}

export function getItemCost({ tenantId, date, itemno, qty }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/item-cost?date=${date}&itemno=${itemno}&qty=${qty}`,
		method: "GET",
	});
}

export function getItemCostDetail({ tenantId, body }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/item-cost-detail`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function finishingJobCost({ tenantId, body }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/finishing-job-cost`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getFinished(tenantId, id) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/job-cost-finished/${id}`,
		method: "GET",
	});
}

export function getBankBook({
	form,
	tenantId,
	bahasa,
	enddate,
	firstdate,
	glacc,
	startdate,
	userid,
	branchid,
}) {
	let tipe = getApi(form, "readall");
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/${tipe}?glacc=${glacc}&startdate=${startdate}&enddate=${enddate}&firstdate=${firstdate}&bahasa=${bahasa}&userid=${userid}&branchid=${branchid}`,
		method: "GET",
	});
}
export function getSaldoAkun({ tenantId, fyear }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/glbalance?fyear=${fyear}`,
		method: "GET",
	});
}
export function getItemWarehouse({ tenantId, units }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/item-per-warehs?units=${units}`,
		method: "GET",
	});
}
export function disposeAktiva(id, body, form) {
	let tipe = getApi(form, "disposed");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function undoDisposeAktiva(id, body, form) {
	let tipe = getApi(form, "undoDisposed");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function checkPeriodeEnd(id, body, form) {
	let { month, year } = body;
	let tipe = getApi(form, "cekPeriodeEnd");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}?month=${month}&year=${year}`,
		method: "GET",
	});
}

export function periodeEnd(id, body, form) {
	let tipe = getApi(form, "periodeEnd");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getDetail(body) {
	const { tenantId, id, form } = body;
	let tipe = getApi(form, "getdetail");
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/${tipe}/${id}`,
		method: "GET",
	});
}

export function getListItemBiaya(body) {
	const { tenantId, bomdate, nomor, tipe } = body;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/bom-itemmat-dlabordet?date=${bomdate}&nomor=${nomor}&tipe=${tipe}`,
		method: "GET",
	});
}

export function getHargaGrouping(id, body) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/item-grouping-price`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function ReportDataTable(body) {
	const { tenantId, fieldname, fieldtype, tablename, reportid } = body;
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/report-data-table?fieldname=${fieldname}&fieldtype=${fieldtype}&tablename=${tablename}&reportid=${reportid}`,
		method: "GET",
	});
}

export function getFormatLaporan(tenantId, body) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/report-drill`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getAllAudit(id, form, formId) {
	let tipe = getApi(form, "getaudit");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}/${formId}`,
		method: "GET",
	});
}

export function getDP(body) {
	const { tenantId, id } = body;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/purchase-invoice-pull/${id}`,
		method: "GET",
	});
}

export function getDPSales(body) {
	const { tenantId, id } = body;
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/delivery-order-detail/${id}`,
		method: "GET",
	});
}

export function ImportData(reqBody) {
	const { tenantId, body, form, type } = reqBody;
	let endPoint = getApi(form, "create");
	let URI = "";
	if (type) {
		URI = `${API_BASE_URL}/tenant/${tenantId}/master/${endPoint}/${type}`;
	} else {
		URI = `${API_BASE_URL}/tenant/${tenantId}/master/${endPoint}`;
	}
	return request({
		url: URI,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getRepairData(tenantId) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/repair-data`,
		method: "POST",
	});
}

export function createMemorizedDetail(id, body, form) {
	let tipe = getApi(form, "create");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getTransaction(tenantId, type) {
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/serial-number-trans?tipe=${type}`,
		method: "GET",
	});
}

export function getTransactionDet(tenantId, invid, type) {
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/serial-number-transdet?id=${invid}&tipe=${type}`,
		method: "GET",
	});
}

export function createEntriesSN(id, body, form) {
	let tipe = getApi(form, "create");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getAvailableSN(tenantId, invid, seq, tipe, itemid) {
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/serial-number-search?invid=${invid}&iseq=${seq}&tipe=${tipe}&vitemid=${itemid}`,
		method: "GET",
	});
}

export function getAllAnggaranAkun(tenantid, fyear) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantid}/master/budgetaccount?fyear=${fyear}`,
		method: "GET",
	});
}

export function getDetailAnggaranAkun(id, glbudgetid) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/budgetaccount-det/${glbudgetid}`,
		method: "GET",
	});
}

export function deleteAnggaranAkun(body) {
	const { id, glbudgetid, glyear } = body;
	return request({
		url:
			API_BASE_URL +
			`/tenant/${id}/master/budgetaccount/${glbudgetid}?fyear=${glyear}`,
		method: "DELETE",
	});
}

export function ApproveByUser({ tenantId }) {
	return request({
		url: `${API_BASE_URL}/tenant/${tenantId}/master/approve-by-user`,
		method: "GET",
	});
}

export function getNumberTax(id, form) {
	let tipe = getApi(form, "getNoPajak");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}`,
		method: "GET",
	});
}

export function checkDBVersion(id) {
	return request({
		url: `${API_BASE_URL}/check-version/${id}`,
		method: "POST",
	});
}

export function loadTenant(id) {
	return request({
		url: `${API_BASE_URL}/load-tenant/${id}`,
		method: "GET",
	});
}

export function getBiayaProduksi(id) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/dlabor-choose`,
		method: "GET",
	});
}

export function getBarangProduksi(id) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/item-choose-std-item`,
		method: "GET",
	});
}

export function getWOdet(id, woid) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/material-release-choose-wodetmat/${woid}`,
		method: "GET",
	});
}

export function getWO(id, form) {
	let tipe = getApi(form, "readall");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/${tipe}?transtype=xx`,
		method: "GET",
	});
}

export function createEndPeriodProd({ tenantId, body, params }) {
	const { month, year } = params;
	return request({
		url:
			API_BASE_URL +
			`/tenant/${tenantId}/master/period-end-prod?bulan=${month}&tahun=${year}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function createCustomizeReport(tenantId, body) {
	return request({
		url: API_BASE_URL + `/tenant/${tenantId}/master/report-format`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function getAllBranch(id) {
	return request({
		url: API_BASE_URL + `/tenant/${id}/master/all-branch`,
		method: "GET",
	});
}

export function getAllSalesman(id, form, idtabel) {
	let tipe = getApi(form, "readall");
	return request({
		url: idtabel
			? `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/${tipe}/${idtabel}`
			: `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/${tipe}`,
		method: "GET",
	});
}

export function getAllSalesmanActivity(id, form) {
	let tipe = getApi(form, "read");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/${tipe}`,
		method: "GET",
	});
}

export function createSalesman(id, form, body) {
	let tipe = getApi(form, "create");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/${tipe}`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function updateSalesman(id, form, body) {
	let tipe = getApi(form, "create");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/${tipe}`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function deleteSalesman(id, form, idtabel) {
	let tipe = getApi(form, "delete");
	return request({
		url: `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/${tipe}/${idtabel}`,
		method: "DELETE",
	});
}

export function updateStatusSchedule(id, body) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/addons/salesman-tracking/update-status-schedule-sales`,
		method: "PUT",
		body: JSON.stringify(body),
	});
}

export function calcNumber(id, body) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/calc-number`,
		method: "POST",
		body: JSON.stringify(body),
	});
}

export function calcHpp(id) {
	return request({
		url: `${SERVERCOGS}/tenant/${id}/master/thread-hpp`,
		method: "GET",
	});
}

export function getInfoSync(id) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/sync-info`,
		method: "GET",
	});
}

export function getStatusHpp(id) {
	return request({
		url: `${API_BASE_URL}/tenant/${id}/master/itemhist-temp`,
		method: "GET",
	});
}
